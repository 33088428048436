import React from "react"
import { graphql } from "gatsby"
import Image from "gatsby-image"
import Layout from "acciondigital-basic-theme/src/components/layout"
import SEO from "acciondigital-basic-theme/src/components/seo"
import InfoContacto from "acciondigital-basic-theme/src/components/InfoContacto"
import FormBasicoNetlify from "acciondigital-basic-theme/src/components/FormBasicoNetlify"
import ImagenUbicacionMaps from "acciondigital-basic-theme/src/components/ImagenUbicacionMaps"
export const query = graphql`
  query {
    placeholderImage: file(relativePath: { eq: "portada.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

// calle independencia #67 segunda sección 70000
// planta alta oficina #3
// lun.:	8:00–17:00
// mar.:	8:00–17:00
// mié.:	8:00–17:00
// jue.:	8:00–17:00
// vie.:	8:00–17:00
// sáb.:	8:00–16:00
// dom.:	Cerrado
const dias = [
  { "lun.:": ["8:00–17:00"] },
  { "mar.:": ["8:00–17:00"] },
  { "mié.:": ["8:00–17:00"] },
  { "jue.:": ["8:00–17:00"] },
  { "vie.:": ["8:00–17:00"] },
  { "sáb.:": ["8:00–16:00"] },
  { "dom.:": ["Cerrado"] },
];
const direccion = [
  "Valentín Carrasco #9",
  "6ta. Sección",
  "C.P. 70000, Juchitán, Oax",
]
const telefono = "971 727 6824"
const irMapsLink =
  "https://www.google.com/maps/dir//Repospan/data=!4m8!4m7!1m0!1m5!1m1!1s0x859555011704cba7:0xc74556d1231dc737!2m2!1d-95.02189399999999!2d16.431566099999998"

const asuntos = [
  {
    value: "consultas",
    texto: "Consultas.",
  },
  {
    value: "quejas",
    texto: "Quejas.",
  },
  {
    value: "comentarios",
    texto: "Comentarios.",
  },
]

export default function Home({ data }) {
  // const texto = "hola world!"
  // const goldenTexto = gold(texto)
  const { placeholderImage } = data
  return (
    <Layout>
      <SEO
        title="Inicio"
        imageSource={placeholderImage.childImageSharp.fluid.src}
      />
      {/* <h1 className="text-center p-3">
        <strong>Respospan</strong>
      </h1> */}

      <Image fluid={placeholderImage.childImageSharp.fluid} />

      <p className="text-center p-2 blockquote">
        Empresa dedicada a la venta al mayoreo y menudeo de materias primas para
        repostería y panificación.
      </p>
      <ImagenUbicacionMaps
        titulo="Mapa con la ubicación de siIE."
        linkMaps="https://www.google.com/maps/place/Repospan/data=!3m1!4b1!4m2!3m1!1s0x859555011704cba7:0xc74556d1231dc737"
        linkImgMaps="https://maps.googleapis.com/maps/api/staticmap?scale=1&size=1600x900&style=feature:poi.business|visibility:off&style=feature:water|visibility:simplified&style=feature:road|element:labels.icon|visibility:off&style=feature:road.highway|element:labels|saturation:-90|lightness:25&format=jpg&language=es-419&region=MX&markers=color:0x5f9bce|16.4315661,-95.021894&zoom=16&client=google-presto&signature=0j490T6fykFmIEDM-zR7jHTXAJ4"
      />

      <InfoContacto
        dias={dias}
        direccion={direccion}
        telefono={telefono}
        irMapsLink={irMapsLink}
      />
      <div className="container">
        <div className="row">
          <div className="col-sm">
            <div>
              <h2>Escríbenos</h2>
            </div>
            <FormBasicoNetlify nombre="contacto-v1" asuntos={asuntos} />
          </div>
        </div>
      </div>
      {/* <div>{texto}</div>
      <div>{goldenTexto}</div>
      <ExampleComponent2 text={goldenTexto}>
        <div>aca</div>
      </ExampleComponent2>
      <Foo text="Foo Props" />
      <Bar text="Bar Props" />
      <Nacho text="Nacho Props" /> */}
    </Layout>
  )
}
