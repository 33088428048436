import React from "react"
import { FaFacebookSquare } from "react-icons/fa"
import { Link } from "gatsby"

const facebookLink = "https://www.facebook.com/"
const facebookName = "Repospan"

const FooterSocialItems = () => {
  return (
    <>
      {facebookLink && facebookName && (
        <a target="_blank" href={facebookLink}>
          <FaFacebookSquare color="facebook" /> {facebookName}
        </a>
      )}
    </>
  )
}

FooterSocialItems.defaultProps = {
  socialItems: [],
}

export default FooterSocialItems
